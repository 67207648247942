<template>
    <div>
        <Banner></Banner>
        <div class="page-container-white">
            <div class="page-title-container content-container">
                <div class="page-title">
                    <p>产品优势</p>
                    <p>PRODUCT ADVANTAGE</p>
                </div>
                <div class="hxjs-body">
                    <div class="advantage_msg">
                        <img src="~@/assets/imgs/ai/adv_gkk.png">
                        <p class="adv_title"><span>高可靠</span></p>
                        <p class="adv_det"><span>基于大量高频的训练，拥有业界先进的语音翻译技术，为您的产品提供精准可靠的模型。</span></p>
                    </div>
                    <div class="advantage_msg">
                        <img src="~@/assets/imgs/ai/adv_ykf.png">
                        <p class="adv_title"><span>易开发</span></p>
                        <p class="adv_det"><span>支持Android/Linux/Windows多平台部署，提供多种开发方式下的SDK，满足不同的开发需求。</span></p>
                    </div>
                    <div class="advantage_msg">
                        <img src="~@/assets/imgs/ai/adv_dcb.png">
                        <p class="adv_title"><span>低成本</span></p>
                        <p class="adv_det"><span>提供专业化的开发指导，支持SDK下载，模型下载，用最低的成本，实现多样功能。</span></p>
                    </div>
                    <div class="advantage_msg">
                        <img src="~@/assets/imgs/ai/adv_gnd.png">
                        <p class="adv_title"><span>多功能</span></p>
                        <p class="adv_det"><span>支持语音识别，语音合成，机器翻译，语音拓展等功能，满足不同行业客户的需求，定制专属产品。</span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-container-grey">
            <div class="page-title-container content-container">
                <div class="page-title">
                    <p>产品功能</p>
                    <p>PRODUCT FUNCTION</p>
                </div>
                <div class="hy_cpgn_body">
                    <div class="fun_msg">
                        <img src="~@/assets/imgs/ai/fun_yysb.jpg">
                        <div>
                            <p class="fun_title"><span>语音识别</span></p>
                            <p class="fun_det"><span>提供一句话识别和实时语音识别多种功能，满足不同的用户需求。</span></p>
                        </div>
                    </div>
                    <div class="fun_msg">
                        <img src="~@/assets/imgs/ai/fun_yyhc.jpg">
                        <div>
                            <p class="fun_title"><span>语音合成</span></p>
                            <p class="fun_det"><span>支持在线和离线合成2种模式，让机器保持流畅的“会言”能力。</span></p>
                        </div>
                    </div>
                    <div class="fun_msg">
                        <img src="~@/assets/imgs/ai/fun_jqfy.jpg">
                        <div>
                            <p class="fun_title"><span>机器翻译</span></p>
                            <p class="fun_det"><span>提供多语种的翻译能力，采用端到端的翻译技术，翻译快，效果好。</span></p>
                        </div>
                    </div>
                    <div class="fun_msg">
                        <img src="~@/assets/imgs/ai/fun_yytz.jpg">
                        <div>
                            <p class="fun_title"><span>语音拓展</span></p>
                            <p class="fun_det"><span>主要是声纹识别，3s即可完成声纹注册，且准确率高达99%，安全性高。</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-container-white">
            <div class="page-title-container content-container">
                <div class="page-title">
                    <p>项目案例</p>
                    <p>PROJECT CASES</p>
                </div>
                <div class="hy_xmal_body">
                    <div class="swiper-container swiper" id="swiper2">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide s-slide-1">
                                <div class="case_name">
                                    <p><span>手机厂商</span></p>
                                    <p><span>国内一家大型手机厂商，产品远销全球各地</span></p>
                                </div>
                                <div class="case_details_hide">
                                    <p><span>某公司是国内一家大型手机厂商，产品远销全球各地。针对该公司手机语音翻译 App 等应用需求，慧言科技为其提供了多语种的移动端语音合成解决方案，并提供了定制化支持，在解决跨语种沟通的问题的同时，保障用户数据安全。</span></p>
                                </div>
                            </div>
                            <div class="swiper-slide  s-slide-2">
                                <div class="case_name">
                                    <p><span>互联网公司</span></p>
                                    <p><span>一家国际化的大型互联网公司，经营多元化的互联网业务</span></p>
                                </div>
                                <div class="case_details_hide">
                                    <p><span>某公司是一家国际化的大型互联网公司，经营多元化的互联网业务。慧言科技为该公司提供了多语种的移动端语音合成解决方案，支持其语音翻译项目落地，用于解决日常交流、商务、旅游等场景中的跨语种沟通问题。</span></p>
                                </div>
                            </div>
                            <div class="swiper-slide  s-slide-3">
                                <div class="case_name">
                                    <p><span>株式会社</span></p>
                                    <p><span>日本的一家AI企业，致力于通过智能语音交互实现和谐的人机交互</span></p>
                                </div>
                                <div class="case_details_hide">
                                    <p><span>株式会社 Harmonized Interactions 是日本的一家AI企业，致力于通过智能语音交互实现和谐的人机交互。慧言科技为其提供了日语语音合成系统私有化 部署，并面向“说话头(talking-head)”场景进行 深度优化定制。</span></p>
                                </div>
                            </div>
                            <div class="swiper-slide  s-slide-4">
                                <div class="case_name">
                                    <p><span>AI企业</span></p>
                                    <p><span>一家大型AI企业，致力于生产可流畅、自然沟通的机器人</span></p>
                                </div>
                                <div class="case_details_hide">
                                    <p><span>某公司是一家大型AI企业，致力于生产可流畅、自然沟通的机器人。针对客户的需求及使用场景，慧言科技为其定制了音素级别时间预测功能，并将前端处理封装为独立模块，便于用户对预处理数据进行人工干预，进一步提高语音合成的自然度。</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-button-prev" id="swiper_prev2"></div>
                    <div class="swiper-button-next" id="swiper_next2"></div>
                </div>
            </div>
        </div>
        <div class="page-container-white">
            <div class="page-title-container content-container">
                <div class="page-title">
                    <p>合作伙伴</p>
                    <p>COOPERATIVE PARTNER</p>
                </div>
                <div class="hzhb-body">
                    <div><img src="~@/assets/imgs/par_11.jpg" alt=""></div> <!--滴滴-->
                    <div><img src="~@/assets/imgs/par_12.jpg" alt=""></div> <!--阿里巴巴-->
                    <div><img src="~@/assets/imgs/par_13.jpg" alt=""></div> <!--阿里云-->
                    <div><img src="~@/assets/imgs/par_14.jpg" alt=""></div> <!--天大-->
                    <div><img src="~@/assets/imgs/par_15.jpg" alt=""></div> <!--软银-->

                    <div><img src="~@/assets/imgs/par_21.jpg" alt=""></div> <!--株式会社-->
                    <div><img src="~@/assets/imgs/par_22.jpg" alt=""></div> <!--恒银金融-->
                    <div><img src="~@/assets/imgs/par_23.jpg" alt=""></div> <!--三甲-->
                    <div><img src="~@/assets/imgs/par_24.jpg" alt=""></div> <!--云译-->
                    <div><img src="~@/assets/imgs/par_25.jpg" alt=""></div> <!--中汽-->

                    <div><img src="~@/assets/imgs/par_31.jpg" alt=""></div> <!--塔米-->
                    <div><img src="~@/assets/imgs/par_32.jpg" alt=""></div> <!--INN-->
                    <div><img src="~@/assets/imgs/par_33.jpg" alt=""></div> <!--津云-->
                    <div><img src="~@/assets/imgs/par_34.jpg" alt=""></div> <!--烽火-->
                    <div><img src="~@/assets/imgs/par_35.jpg" alt=""></div> <!--天津广电-->
                </div>
            </div>
        </div>
        <div class="page-container-grey">
            <div class="page-hzzx">
                <p><span>点击下方按钮，获得我们的专属支持</span></p>
                <div><button><a href="mailto:bd@huiyan-tech.com" target="_blank">合作咨询</a></button></div>
            </div>
        </div>
    </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import Swiper from "swiper";
import Banner from './Banner.vue'
export default {
    name: 'ai',
    components: {
        Banner
    },
    mounted () {
        this.$nextTick(() => {
            new Swiper("#swiper2", {
                slidesPerView: 4,
                spaceBetween: 30,
                slidesPerGroup: 4,
                // loop: true,
                // loopFillGroupWithBlank: true,
                navigation: {
                    nextEl: "#swiper_prev2",
                    prevEl: "#swiper_prev2",
                },
            });
        })
    }
}
</script>

<style lang="less" scoped>
@import '../assets/css/product.css';
.s-slide-1 {
    background: url('~@/assets/imgs/ai/case_img1.jpg') no-repeat center center;
    background-size: 100% 100%;
}
.s-slide-2 {
    background: url('~@/assets/imgs/ai/case_img2.jpg') no-repeat center center;
    background-size: 100% 100%;
}
.s-slide-3 {
    background: url('~@/assets/imgs/ai/case_img3.jpg') no-repeat center center;
    background-size: 100% 100%;
}
.s-slide-4 {
    background: url('~@/assets/imgs/ai/case_img4.jpg') no-repeat center center;
    background-size: 100% 100%;
}
</style>